<template>
<div>
  <b-card no-body>
    <b-card-header class="pb-50">
      <b-row no-gutters class="w-100">
        <b-col>
          <h5>{{ msg("Filters") }}</h5>
        </b-col>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-button variant="link" v-bind:disabled="fetchingAll" v-b-tooltip v-bind:title="$t('sync')" class="p-0 mr-1" style="color: inherit" @click="tryFetchAll">
              <i class="fa-solid fa-download" size="font-size: 16px"></i>
            </b-button>
            <b-link  style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- Id -->
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ msg("Id") }}</label>
          <b-form-input size="sm"
              v-model="filter.id"
              class="d-inline-block mr-1"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ msg("Address") }}</label>
          <b-form-input size="sm" :debounce="500"
              v-model="filter.address"
              class="d-inline-block mr-1"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb text-right">
          <b-img style="max-height: 100%; max-width: 150px" v-bind:src="priceLabsLogo"/>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
  <b-card>
    <!-- Table Top -->
    <b-row no-gutters class="mb-1">
      <!-- Per Page -->
      <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
        <label>{{ msg('Show') }}</label>
        <v-select
            v-model="pagination.size"
            :options="[10, 25, 50, 100]"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            @input="refresh"
        />
        <label>{{ msg('entries') }}</label>
      </b-col>
      <!-- Search -->
      <b-col cols="12" md="6">
      </b-col>
    </b-row>
    <div v-handy-scroll class="table-responsive">
      <b-table ref="plTable" striped hover :items="items" :fields="fields" v-bind:busy="isLoading" @row-clicked="onItemSelected">
        <template #cell(id)="data">
          <span>{{ data.value }}</span>
        </template>
        <template #cell(pms)="data">
          <span>{{ data.value }}</span>
        </template>
        <template #cell(name)="data">
          <b-link style="color: #0b2956;" @click="$router.push('/real_estate/edit/'+data.item.propertyId)">{{ data.value }}</b-link>
        </template>
        <template #cell(fullAddress)="data">
          <span>{{ data.value }}</span>
        </template>
        <template #cell(min)="data">
          <span>{{ data.value }}</span>
        </template>
        <template #cell(base)="data">
          <span>{{ data.value }}</span>
        </template>
        <template #cell(max)="data">
          <span>{{ data.value }}</span>
        </template>
        <template #cell(id)="data">
          <span>{{ data.value }}</span>
        </template>
        <template #cell(occupancy.next7Days)="data">
          <b-badge :variant="getPricesVariant(data.item.occupancy.next7DaysStatus)" style="white-space: nowrap" v-if="data.value != null && data.value.trim() !== ''">
            <span>{{ data.value }} / {{data.item.occupancy.marketNext7Days}}</span>
          </b-badge>
        </template>
        <template #cell(occupancy.next30Days)="data">
          <b-badge :variant="getPricesVariant(data.item.occupancy.next30DaysStatus)" style="white-space: nowrap" v-if="data.value != null && data.value.trim() !== ''">
            <span>{{ data.value }}/{{data.item.occupancy.marketNext30Days}}</span>
          </b-badge>
        </template>
        <template #cell(occupancy.next60Days)="data">
          <b-badge :variant="getPricesVariant(data.item.occupancy.next60DaysStatus)" style="white-space: nowrap" v-if="data.value != null && data.value.trim() !== ''">
            <span>{{ data.value }}/{{data.item.occupancy.marketNext60Days}}</span>
          </b-badge>
        </template>
        <template #cell(occupancy.next90Days)="data">
          <b-badge :variant="getPricesVariant(data.item.occupancy.next90DaysStatus)" style="white-space: nowrap" v-if="data.value != null && data.value.trim() !== ''">
            <span>{{ data.value }}/{{data.item.occupancy.marketNext90Days}}</span>
          </b-badge>
        </template>

        <template #cell(isHidden)="data">
          <span>{{ data.value ?  msg('yes') : "" }}</span>
        </template>
      </b-table>
    </div>
    <!--Table Paginator-->
    <div class="paginator text-center">
      <!--Paginator-->
      <div class="ml-2" style="display: inline-block ">
        <b-pagination
            :disabled="isLoading"
            limit="11"
            :per-page="pagination.size"
            :current-page="pagination.page"
            :total-rows="pagination.total"
            align="center"
            @input="onPaginationChange"
        />
      </div>
      <div class="d-inline-block ml-1">
        <strong>{{ msg('Total') }}</strong> {{ pagination.total }}
      </div>
    </div>
  </b-card>
  <b-modal size="xl" ref="priceLabsModal" centered hide-footer @hidden="()=>selectedItem=null">
    <template #modal-title>
      <h4>{{(selectedItem || {}).fullAddress}}</h4>
    </template>
    <div v-if="selectedItem !== null">
      <real-estate-price-labs  v-bind:price-labs="selectedItem" @onUpdate="updateItem"/>
    </div>
  </b-modal>
</div>
</template>

<script>
import {mapActions} from "vuex";
import RealEstatePriceLabs from "@/components/panels/realEstates/RealEstatePriceLabs.vue";
import {PriceLabs} from "@/model/RealEstate/priceLabs/PriceLabsDetails"

export default {
  name: "priceLabsView",
  components: {RealEstatePriceLabs},
  data: () => ({
    isLoading: false,
    priceLabs:[],
    filter:{
      address: '',
    },
    pagination: {
      page: 1,
      size: 10,
      total: 0,
      totalPages: 0
    },
    selectedItem: null,
    fetchingAll: false
  }),
  watch: {
    filter: {
      handler() {
        this.refresh()
      },
      deep: true
    }
  },
  created() {
    this.refresh();
    this.$root.$on('crm::branch-updated', this.refresh)
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.refresh)
  },
  computed:{
    items() {
      return this.priceLabs
    },
    fields() {
      let $this = this
      return [
        {key: 'id', label: 'id'},
        {key: 'pms', label: 'PMS'},
        {key: 'name', label: $this.msg('name')},
        {key: 'fullAddress', label: $this.msg('address')},
        {key: 'min', label: $this.msg('Min')},
        {key: 'base', label: $this.msg('Base')},
        {key: 'max', label: $this.msg('Max')},
        {key: 'isHidden', label: $this.msg('hidden')},
        {key: 'occupancy.next7Days', label: $this.$t('nextDays',{days:'7'})},
        {key: 'occupancy.next30Days', label: $this.$t('nextDays',{days:'30'})},
        {key: 'occupancy.next60Days', label: $this.$t('nextDays',{days:'60'})},
        {key: 'occupancy.next90Days', label: $this.$t('nextDays',{days:'90'})},
        // {key: 'pushEnabled', label: 'pushEnabled'},
        // {key: 'pushEnabled', label: 'pushEnabled'},
      ]
    },
    priceLabsLogo(){
      return require("@/assets/images/platforms/priceLabs.png")
    }
  },
  methods:{
    ...mapActions('realEstatePriceLabs', ['getAllPriceLabs', 'fetchAll']),
    async refresh() {
      this.isLoading = true;
      try {
        let data = await this.getAllPriceLabs({...this.pagination, ...this.filter})
        this.priceLabs = (data.PriceLabs || []).map(p=>PriceLabs(p));
        this.pagination = data.pagination;
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false;
      }
    },
    onPaginationChange(page) {
      this.pagination.page = page
      this.refresh()
    },
    onItemSelected(item) {
      this.selectedItem = item
      this.$refs.priceLabsModal.show()
    },
    updateItem(item){
      console.log('updateItem', item)
      this.refresh()
    },
    async tryFetchAll(){
      this.fetchingAll = true
      try {
        await this.fetchAll()
        this.refresh();
      }finally {
        this.fetchingAll = false
      }
    },
    getPricesVariant(nextDaysStatus){
      if(nextDaysStatus === 'ABOVE_MARKET'){
        return "success";
      }
      if(nextDaysStatus === 'UNDER_MARKET'){
        return "danger";
      }
      return "primary"
    }
  }
}
</script>



<style scoped>

</style>
